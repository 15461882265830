import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import routes from './routes';

const loading = (
  <div className="pt-3 text-center">
  <div className="sk-spinner sk-spinner-pulse fw-bold fs-1">Loading</div>
</div>
);

const App = () => {
  return (

    <div className="App container-fluid p-0 font-monospace">
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Routes>
            {routes.map((route, idx) => (
              <Route key={idx} path={route.path} element={<route.component />} />
            ))}
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </div>
  );
};

export default App;
