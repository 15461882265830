import React from 'react';

const Home = React.lazy(() => import('./Home'));
const Work = React.lazy(() => import('./components/work/Work'));
const Life = React.lazy(() => import('./components/life/Life'));
const Letter = React.lazy(() => import('./components/shared/Letter'));

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: 'work', name: 'Work', component: Work },
  { path: 'life', name: 'Life', component: Life },
  { path: 'posts/:id', name: 'Letter', component: Letter },
];

export default routes;
